import { React, useState, useRef } from 'react';
import { StyleSheet, Text, View, ImageBackground, Dimensions } from 'react-native';
//import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import AutoComplete from '../component/autocomplete';
import { Animated, Easing } from "react-native";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const HomeScreen = ({ navigation }) => {

    const [recommendations, setRecommendations] = useState([]);
    const [descriptions, setdescriptions] = useState([]);

    const [recommendationHeight] = useState(new Animated.Value(0));
    const [hoverIndex, setHoverIndex] = useState(-1);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
      };
      
      const handleMouseLeave = () => {
        setHoverIndex(-1);
      };

    const handleRecommendationsLayout = (event) => {
        Animated.timing(recommendationHeight, {
            toValue: recommendations.length ? 300 : 0, // set the new height based on the number of recommendations
            duration: 50, // set the animation duration
            easing: Easing.in,
            useNativeDriver: true, // set this to false if you're not using the Animated module from Expo
          }).start();
      };


  const fetchRecommendations = async (place) => {
    const url = `https://iw0i8ipcwd.execute-api.ca-central-1.amazonaws.com/prod/recommendation?category=${place.types.join(",")}`;
    const response = await fetch(url);
    const data = await response.json();
    setRecommendations(data[0]);
    setdescriptions(data[1]);



  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("./pexels-joyston-judah-933054.jpeg")}
        style={styles.backgroundImage}
      >
        <View style={styles.contentContainer}>

          <View style={styles.textContainer}>
            <Text style={styles.headingText}>Find the best credit card for places</Text>
            <Text style={styles.subheadingText}>Beta v1.0.0</Text>
          </View>

          <View style={styles.inputContainer}>
            <AutoComplete onPlaceChange={fetchRecommendations} />
          </View>

          <Animated.View style={[styles.recommendationsContainer, {height: recommendationHeight}]} onLayout={handleRecommendationsLayout}>
            {recommendations.map((recommendation, index) => (
              <View key={index} 
              style={[styles.recommendationContainer, hoverIndex === index && styles.recommendationContainerHover,]}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave()}>
                <Text style={styles.recommendationText}>{recommendation}</Text>
                <Text style={styles.descriptionText}>{descriptions[index]}</Text>
              </View>
            ))}
          </Animated.View>
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      height: windowHeight,
    },
    backgroundImage: {
        flex: 1,
        resizeMode: "cover",
    },
    contentContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    textContainer: {
        alignItems: "center",
        marginBottom: 20,
    },
    headingText: {
        fontSize: 36,
        fontWeight: "bold",
        marginBottom: 20,
        textAlign: "center",
        color: "#fff",
    },
    subheadingText: {
        fontSize: 25,
        fontWeight: "100",
        textAlign: "center",
        color: "#fff",
        fontFamily: "Thin 100",
    },
    inputContainer: {
        marginVertical: 10,
    },
    recommendationsContainer: {
        marginTop: 10,
        alignItems: "center",
    },
    recommendationContainer: {
      backgroundColor: "rgba(255, 180, 136,0.7)",
      borderRadius: 5,
      marginVertical: 5,
      marginHorizontal: 10,
      padding: 10,
      paddingHorizontal: 20,
      width: 300,
    },
    recommendationText: {
      fontSize: 15,
      color: "#000",
      fontWeight: 'bold',
    },
    descriptionText: {
        marginVertical: 10,
        fontSize: 15,
        color: "#000",
    },
    recommendationContainerHover: {
        backgroundColor: "rgba(255, 150, 106,1)",
      },
  });
export default HomeScreen;

