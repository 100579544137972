import { useRef, useEffect } from "react";
import "./styles.css";

const AutoComplete = ({ onPlaceChange }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
  componentRestrictions: { country: "ca" },
    fields: ["address_components", "geometry", "icon", "name", "types"]
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      onPlaceChange(place);
      console.log({ place });
    });
  }, [onPlaceChange]);

  return (
    <div>
    <input
      ref={inputRef}
      style={{
        width: "300px",
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "10px",
        border: "none",
        outline: "none",
      }}
    />
    </div>
  );
};
export default AutoComplete;